import { Button, Center, Flex, Link, Text } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import UTMLink from "@components/UTMLink";
import { COMMUNITY_DETAILS, getCommunityIcon } from "@constants/common";

const Footer = () => {
  return (
    <Flex bg="#075A5A" w="full" justifyContent="center">
      <Flex
        p={{ base: "64px 16px", md: "80px 160px 24px 160px" }}
        bg="#075A5A"
        flexDir="column"
        maxW="1512px"
        w="full"
      >
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          color="white"
          minH="256px"
          gap="128px"
        >
          <Flex flexDir="column" gap="24px" alignItems="flex-start">
            <BaseImage
              src="/assets/kelp_white.svg"
              alt="kelp"
              height={48}
              width={128}
            />

            <Flex gap="8px">
              {COMMUNITY_DETAILS.map((data) => (
                <Link
                  cursor="pointer"
                  href={data.link}
                  target="_blank"
                  rel="nofollow"
                  key={data.name}
                >
                  <Center
                    key={data.key}
                    borderRadius="50%"
                    bg="backgroundDivider"
                    w="40px"
                    h="40px"
                  >
                    {data.key && getCommunityIcon(data.key) ? (
                      <BaseImage
                        width={24}
                        height={24}
                        src={getCommunityIcon(data.key) || ""}
                        alt={data.key}
                      />
                    ) : null}
                  </Center>
                </Link>
              ))}
            </Flex>

            <UTMLink
              href="/restake"
              _hover={{
                borderBottom: "none",
              }}
            >
              <Button
                variant="unstyled"
                p="16px 64px"
                borderRadius="16px"
                border="1px solid #F7F9F9"
                h="56px"
                fontWeight="600"
                lineHeight="24px"
                fontSize="14px"
                color="#F7F9F9"
              >
                Restake now
              </Button>
            </UTMLink>
          </Flex>
          {/* <Flex
          justifyContent="space-around"
          fontSize="22.5px"
          fontWeight="600"
          lineHeight="33.75px"
          width="100%"
        >
          <Flex flexDir="column" gap="40px">
            <Text>Restake LSTs</Text>
            <Text>Governance</Text>
          </Flex>
          <Flex flexDir="column" gap="40px">
            <Text>Kelp Miles</Text>
            <Text>Docs</Text>
          </Flex>
        </Flex> */}
        </Flex>
        <Flex
          justify="space-between"
          p="2rem 0"
          fontSize="14px"
          fontWeight="400"
          lineHeight="21px"
          color="#ffffffcc"
          gap="8px"
          flexDir={{ base: "column", md: "row" }}
        >
          <Text>© Copyright 2023 Kelp Dao. All rights reserved.</Text>
          <Flex alignItems="center" gap="4px">
            <UTMLink href="/terms-of-service" target="_blank">
              <Text>Terms of service</Text>
            </UTMLink>
            <Text>|</Text>
            <UTMLink href="/privacy-policy" target="_blank">
              <Text>Privacy policy</Text>
            </UTMLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
